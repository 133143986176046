<template>
  <b-card>
    <div class="row align-items-center">
      <div class="col-3">
        <div class="rounded-box">
          <div class="outer"
          :style="'background-image: url('+appLogoImage+'); background-repeat: no-repeat;background-size: 150px;'">
            <div class="inner">
              <input class="inputfile" type="file" name="imageLogo" accept=".jpg, .bmp, .png" @input="selectLogo">
              <label>
                <div v-if="loading">
                  <b-spinner medium style="margin: 0 0 -3px -3px;"/>
                </div>
                <feather-icon v-else
                  badge-classes="bg-light"
                  class="text-body"
                  icon="CameraIcon"
                  size="21"
                  style="margin: 0 0 3px -2px;"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 d-flex align-items-center">
        <div>
          <h1 class="brand-text mb-0 text-primary" v-if="!isEdit">
            {{ appName }}
          </h1>
          <input type="text" v-model="appName" class="form-control font-weight-bold text-primary h1" style="margin-top: 7px; width: 300px;" v-else>
        </div>
        <div class="ml-2">
          <div v-if="isEdit && loading">
            <b-spinner small />
            Loading...
          </div>
          <button class="btn btn-outline-warning btn-sm" @click.prevent="editAppName" v-else-if="!isEdit && !loading">
            <feather-icon
              badge-classes="bg-light"
              class="text-body mt-0"
              icon="EditIcon"
              size="17"
            />
          </button>
          <button class="btn btn-outline-primary btn-sm form-control" @click.prevent="saveAppName" v-else>
            <feather-icon
              badge-classes="bg-light"
              class="text-body mt-0"
              icon="CheckIcon"
              size="17"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <div class="col">
        <span class="badge badge-warning mt-3">Ketuk Icon Tombol untuk mengganti Logo dan Nama Aplikasi</span>
      </div>
    </div>
  </b-card>
</template>
<script>
import {BCard, BImg, BSpinner} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BImg,
    BSpinner,
  },
  data() {
    return {
      dataurl: 'application',
      isEdit: false,
      loading: false,
      data: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData(){
        this.$http.get(this.dataurl).then(res=>{
          this.data = res.data.data
          this.appLogoImage = this.getConfigData('appLogoImage')
          this.appName = this.getConfigData('appName')
          this.$store.commit('appConfig/UPDATE_APP', {
            appName: this.appName,
            appLogoImage: this.appLogoImage,
          })
        })
    },
    getConfigData(config){
      for (let index = 0; index < this.data.length; index++) {
        const configData = this.data[index];
        if (configData.config === config){
          return configData.data_url ? configData.data_url : configData.data
        }
      }
    },
    editAppName() {
      this.isEdit = true
    },
    async saveAppName() {
      this.loading = true
      let params = {
        config: 'appName',
        type_data: "string",
        data: this.appName,
      }

      this.$http.post(this.dataurl, params)
      .then(res =>{
        this.successSubmit()
        this.isEdit = false
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
      this.loading = false
      this.fetchData()
    },
    async selectLogo(data){
      if(data){
        this.loading = true
        let formData = new FormData()
        formData.append('file', data.target.files[0])
        let url = 'upload/image'

        let resFile = await this.$http.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).catch(err=>{this.errorSubmit(err)})
        if(resFile){

          let params = {
            config: 'appLogoImage',
            type_data: "image",
            data: resFile.data.path,
          }

          this.$http.post(this.dataurl, params)
          .then(res =>{
            this.successSubmit()
            this.appLogoImage = res.data.data.data_url;
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          this.loading = false
        }
        this.loading = false
        this.fetchData()
      }
    },
    successSubmit(){
      this.fetchData()
      this.$bvToast.toast('Berhail upload', {
        title: 'Success',
        solid: true,
        variant:'success'
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="css">
.rounded-box {
  width: 150px;
  height: 150px;
  display: block;
  margin: 0 auto;
}

.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important; /* any size */
  max-height: 150px !important; /* any size */
  margin: auto;
  background-color: #ffffff;
  /* border-radius: 100%; */
  position: relative;
  }

.inner {
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  /* border: 1px solid gray; */
}

.inner:hover {
  background-color: #97bee9;
}
.inputfile {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
}
.inputfile + label {
    font-size: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    pointer-events: none;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
}
.inputfile + label svg {
    fill: #fff;
}
</style>
